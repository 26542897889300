import styled, { keyframes } from "styled-components"
import { GlobalPageStyles } from "../util/GlobalPageStyles"
import { ReactComponent as DexscraperHeadingImage } from "../res/images/dexsniper.svg"
import { ReactComponent as CrosshairImage } from "../res/images/crosshair.svg"
import { Colours } from "../res/Colours"
import { Sizes } from "../res/Sizes"
import ReCAPTCHA from "react-google-recaptcha"
import { useState } from "react"

export const Index = () => {
    const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>()

    const onRecaptcha = (token: string | null) => {
        setRecaptchaToken(token ?? undefined)
    }

    const SubmitButton = recaptchaToken ? <Submit type={"submit"} /> :
        <ReCAPTCHA sitekey={"6LcExrgiAAAAACEJ9LD8XvZFBc3OSRq_r9LCCGYr"}
                   onChange={onRecaptcha}
                   theme={"dark"} />

    return (
        <Page>
            <GlobalPageStyles />
            <Wrapper>
                <HeadingWrapper>
                    <DexscraperHeading />
                    <Crosshair />
                </HeadingWrapper>
                <Content>
                    <MainText>Under construction, join our mailing list to keep updated</MainText>
                    <Form name={"emails"} method={"post"}>
                        <input type="hidden" name="form-name" value="emails" />
                        <input type="hidden" name="g-recaptcha-response" value={recaptchaToken} />

                        <EmailInput
                            type={"email"}
                            name={"email"}
                            placeholder={"Email..."}
                            required
                        />

                        {SubmitButton}
                    </Form>
                </Content>
            </Wrapper>
        </Page>
    )
}

const Page = styled.div`
  background: ${Colours.primary};
  height: 100%;
  font-family: "VT323", serif;
`

const Wrapper = styled.div`
  background: ${Colours.primary};
  max-width: 60%;
  @media (max-width: 768px) {
    max-width: 80%;
  }

  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`

const HeadingWrapper = styled.div`
  background: ${Colours.primary};
  position: relative;
  margin-top: ${Sizes.extraLarge * 2}px;
`

const DexscraperHeading = styled(DexscraperHeadingImage)``

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Crosshair = styled(CrosshairImage)`
  position: absolute;
  height: 100%;
  margin: auto auto auto 85.7%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: ${rotate} 8s linear infinite;
`

const Content = styled.div`
  align-self: center;
  display: flex;
  margin-top: 20vh;
  flex-direction: column;
  align-items: stretch;
`

const MainText = styled.p`
  font-size: 200%;
  color: ${Colours.text.primary};
  padding-right: ${Sizes.standard}px;
  text-align: center;
`

const Form = styled.form`
  display: flex;
  align-items: center;
  margin-top: ${Sizes.standard}px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const EmailInput = styled.input`
  background: none;
  border: 1px solid white;
  border-radius: ${Sizes.small}px;
  margin-right: ${Sizes.standard}px;
  padding: ${Sizes.small}px;
  caret-color: ${Colours.text.primary};
  color: ${Colours.text.primary};
  transition: all 0.25s ease;
  flex: 1;
  @media (max-width: 768px) {
    align-self: stretch;
    margin-bottom: ${Sizes.standard}px;
    margin-right: 0;
  }
`
const Submit = styled.input`
  background: none;
  border: 1px solid white;
  padding: 3px ${Sizes.small}px;
  border-radius: ${Sizes.small}px;
  color: ${Colours.text.primary};
  font-size: 150%;
  cursor: pointer;
  transition: all 0.25s ease;

  :hover {
    color: ${Colours.accent};
    border-color: ${Colours.accent};
  }
  
  @media (max-width: 768px) {
    align-self: stretch;
  }
`
