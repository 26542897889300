import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { GlobalStyles } from "./util/GlobalStyles"
import { Index } from "./routes"

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Index />} />
        </Routes>
        <GlobalStyles />
    </BrowserRouter>
)

export default App
